.review_section{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100%);
}
.review_section_card_container{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 100px;
}
.review_section_card{
    background-color: rgb(26, 25, 23, 0.4);
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 350px;
    margin-bottom: 30px;
    padding: 30px;
    position: relative;
    width: calc(49%);
    z-index: -2;
}
.review_section_card_icon{
    left: 30px;
    opacity: 0.1;
    position: absolute;
    top: 30px;
    z-index: -1;
}
.review_section_card_content{
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    p{
        font-size: 16px;
        line-height: 1.1;
        margin: 0;
    }
}

@media screen and (max-width: 767px) {
    .review_section_card_container{
        flex-direction: column;
    }
    .review_section_card{
        margin-bottom: 30px;
        width: calc(100%);
    }
}