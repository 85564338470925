.table_button {
    background-color: #dad1ca;
    border: 1px solid #1a1917;
    border-radius: 5px;
    cursor: pointer;
    color: #1a1917;
    font-family: 'montserrat';
    font-size: 15px;
    height: 40px;
    width: 150px;

    &:hover {
        background-color: transparent;
        color: #dad1ca;
    }
}