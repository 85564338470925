.home_page_blog_container{
    --widthSize: calc(25%);
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100%);
}
.home_page_blog{
    cursor: pointer;
    height: 28vw;
    position: relative;
    width: var(--widthSize);
    img{
        width: calc(100%);
        height: calc(100%);
    }
}
.home_page_blog_overlay{
    background-color: rgb(0, 0, 0, 0.7);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: 15px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;

    &:hover{
        background-color: rgb(0, 0, 0, 0.9);
        .home_page_blog_content{
            display: block;
        }
    }
}
.home_page_blog_content{
    display: none;
}

@media screen and (max-width: 1279px) {
    .home_page_blog_content{
        p {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 1023px) {
    .home_page_blog_container {
        --widthSize: calc(50%);
    }
    .home_page_blog{
        height: 52vw;
    }
    .home_page_blog_overlay{
        justify-content: center;
    }
    .home_page_blog_content{
        h4{
            text-align: center;
        }
        p {
            text-align: center;
        }
    }
}
@media screen and (max-width: 575px) {
    .home_page_blog_container {
        --widthSize: calc(100%);
    }
}
