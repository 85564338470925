.text_field_container{
    display: flex;
    flex-direction: column;
    width: calc(50% - 15px);
    margin: 10px 0;
}

.text_field {
    background-color: #2b2a28 !important;
    border: 1px solid #dad1ca;
    border-radius: 5px;
    color: #dad1ca;
    font-family: 'montserrat';
    font-weight: 400;
    font-size: 15px;
    height: 50px;
    line-height: 18px;
    margin: 0;
    padding: 0;
    text-indent: 10px;
    width: calc(100%);
    
    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        margin-right: 15px;
        filter: invert(0.8);
        font-size: 18px;
    }
}

.time_field{
    background-color: #2b2a28 !important;
    border: 1px solid #dad1ca;
    border-radius: 5px;
    height: 50px;
    width: calc(100%);
    p{
        color: #dad1ca;
        font-size: 15px;
        line-height: 3.5;
        margin: 0;
        text-indent: 10px;
    }
}

.text_field_full_width{
    width: calc(100%);
}

.text_field_label{
    color: #dad1ca;
    font-family: 'montserrat';
    font-size: 16px;
    margin-bottom: 10px;
}

.text_field_icon{
    position: relative;
    img{
        cursor: pointer;
        position: absolute;
        top: 42px;
        right: 5px;
        z-index: 9;
    }
}


@media screen and (max-width: 767px) {
    .text_field_container{
        width: calc(100%);
    }
    .text_field{
        font-size: 14px;
        height: 40px;
    }
    .text_field_label{
        font-size: 14px;
        margin-bottom: 5px;
    }
    .text_field_icon{
        img{
            top: 30px;
        }
}
}