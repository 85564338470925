.event_action {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
}

.table_data{
    width: calc(30%);

    &:last-child{
        width: calc(10%);
    }

    span{
        // white-space: nowrap;
        // width: 250px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: inline-block;
        margin-right: 5px;
        font-size: 15px;
        
        &:not(:last-child)::after {
            content: ",";
        }
    }
}

.event_icon {
    cursor: pointer;
}

@media screen and (max-width: 575px) {
    .table_data{
        span{
            display: inline-block;
            width: 150px;
        }
    }
}