.walk-list-heading{
    font-size: 42px;
    color: #fff;
    font-weight: 500;
    margin: 20px 0px;
}
.divider {
    margin: 20px 0px;
    background-color: #fff;
    height: 2px;
    width: 100%;
    border: none;
}
.walklist-table {
    margin: 10px 0px;
}