.service_section{
    background-image: url('../../../assets/images/service/service-section-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
}
.service_section_carousel_container{
    display: flex;
    width: calc(100%);
}
.home_page_service{
    cursor: pointer;
    text-decoration: none;
}
.service_section_swiper{
    width: calc(100%);
    .swiper-slide{
        height: 500px !important;
        width: 700px !important;
    }
}
.service_section_carousel_card{
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    display: flex;
    height: calc(100%);
    position: relative;
    justify-content: center;
    overflow: hidden;
}
.service_section_card_img{
    position: absolute;
    height: calc(100%);
    width: calc(100%);
    z-index: -1;
}


@media screen and (max-width: 1559px) {
    .service_section_swiper {
        .swiper-slide {
            height: 30vw !important;
            width: calc(45%) !important;
        }
    }
}
@media screen and (max-width: 1023px) {
    .service_section_swiper {
        .swiper-slide {
            height: 60vw !important;
            width: calc(70%) !important;
        }
    }
}
@media screen and (max-width: 575px) {
    .service_section_swiper {
        .swiper-slide {
            height: 80vw !important;
            width: calc(90%) !important;
        }
    }
}