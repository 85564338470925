.container{
    width: 1440px;
    margin: 0 auto;
}
h1,h2,h3,h4{
    color: #dad1ca;
    font-family: 'playfair display';
    margin: 0;
    padding: 0;
}
h1{
    font-size: 100px;
    line-height: 1.1;
}
h2 {
    font-size: 70px;
    font-weight: 300;
    text-align: center;
}
h3 {
    font-size: 55px;
    font-weight: 300;
    text-align: center;
}
h4{
    font-size: 30px;
    font-size: 300;
    text-align: left;
}
.section_heading{
    margin-bottom: 100px;
    text-overflow: clip;
}
.event-page-section-heading{
    margin-bottom: 25px;
    text-overflow: clip;
}
.event-page-section-sub-heading{
    display: block;
    margin: 25px auto;
    text-overflow: clip;
    color: #fff;
    font-size: 28px;
}
p{
    color: #fff;
    font-family: 'montserrat';
    font-size: 20px;
}
.section{
    margin: 200px auto;
}
.page_section {
    margin: 100px auto;
}

.form_container {
    background-color: #1a1917;
    border-radius: 10px;
    box-sizing: border-box;
    margin: 0px auto;
    padding: 30px;
    width: calc(100%);
}
.table_container{
    overflow-x: auto;
}
table {
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    box-sizing: border-box;
    color: #fff;
    // border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 15px 10px;
}

thead tr {
    background-color: #1a1917 !important;
}

tr {
    &:nth-child(even) {
        background-color: #1a1917;
    }

    &:nth-child(odd) {
        background-color: #2b2a28;
    }
}

.event_list_section{
    display: flex;
}
.event_list_main{
    width: calc(75%);
}
.event_list_iframe{
    width: 560px;
    height: 315px;
    margin: 10px;
}

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #1a1917;
    border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media screen and (max-width: 1559px) {
    .container {
        width: 1100px;
    }
    h1{
        font-size: 80px;
    }
    h4{
        font-size: 22px;
    }
    .candlelist_main{
        width: calc(70%);
    }
}

@media screen and (max-width: 1279px) {
    .container {
        width: calc(100% - 100px);
    }
    h2{
        font-size: 50px;
    }
    h3{
        font-size: 40px;
    }
    h4 {
        font-size: 18px;
    }
    p{
        font-size: 18px;
    }
    
}

@media screen and (max-width: 1023px) {
    h1{
        font-size: 70px
    }
    .section{
        margin: 100px auto;
    }
    .candlelist_section{
        flex-direction: column;
    }
    .candlelist_main{
        width: calc(100%);
    }
    .candlelist_iframe{
        height: 50vw;
        margin: 0;
        width: calc(100%);
    }
}

@media screen and (max-width: 767px) {
    .container {
        width: calc(100% - 50px);
    }
    .form_container {
        padding: 15px;
    }
    h2{
        font-size: 40px;
    }
}

@media screen and (max-width: 575px) {
    h1 {
        font-size: 30px;
    }
    h2 {
        font-size: 25px;
    }
    h3{
        font-size: 25px;
    }
    p {
        font-size: 16px;
    }
    .section_heading{
        margin-bottom: 50px;
    }
}