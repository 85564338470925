.music_player{
    background-color: rgb(26, 25, 23) !important;
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    .rhap_time,.rhap_current-time{
        color: #fff !important;
    }
}