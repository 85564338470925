.textarea_container {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    margin: 10px 0;
}

.textarea {
    background-color: #2b2a28 !important;
    border: 1px solid #dad1ca;
    border-radius: 5px;
    box-sizing: border-box;
    color: #dad1ca;
    font-family: 'montserrat';
    font-weight: 400;
    font-size: 15px;
    height: 120px;
    line-height: 18px;
    margin: 0;
    padding: 15px;
    width: calc(100%);

}

.textarea_label {
    color: #dad1ca;
    font-family: 'montserrat';
    font-size: 16px;
    margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
    .textarea_container {
        width: calc(100%);
    }
    .textarea {
        font-size: 14px;
        height: 100px;
    }

    .textarea_label {
        font-size: 14px;
        margin-bottom: 5px;
    }
}