.header_swiper{
    position: relative;
}
.header_image{
    object-fit: cover;
    object-position: 0 60%;
    height: 90vh;
    width: 100%;
    z-index: -1;
}

.header_overlay{
    background-color: rgb(43, 42, 40, 0.7);
    height: inherit;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
}
.header_content_container{
    padding-top: 250px;
    width: calc(45%);
}
.swiper-pagination-bullet{
    background: #fff !important;
}

@media screen and (max-width: 1279px) {
    .header_content_container {
        padding-top: 200px;
        width: calc(50%);
    }
}

@media screen and (max-width: 1023px) {
    .header_content_container {
        padding-top: 200px;
        width: calc(100% - 100px);
    }
}

@media screen and (max-width: 575px) {
    .header_content_container {
        padding-top: 170px;
        width: calc(100% - 20px);
    }

    .header_image{
        object-fit: cover;
        object-position: left 90%;
        height: 55vh;
        width: 100%;
        z-index: -1;
    }
}