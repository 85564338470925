.footer{
    background-color: #1a1917;
    padding: 50px 0;
    width: calc(100%);
    p{
        font-size: 16px;
    }
}
.footer_inner{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footer_left_col{
    display: flex;
    flex-direction: column;
    div{
        display: flex;
        flex-direction: row;
        width: calc(100%);
        p{
            margin: 5px;
            margin-left: 30px;
        }
    }
}
.footer_right_col{
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    width: calc(40%);
}
.footer_newsletter{
    width: calc(100%);

    form{
        position: relative;
        input{
            background-color: #2b2a28;
            border: none;
            border-radius: 5px;
            color: #fff;
            font-size: 15px;
            height: 50px;
            overflow: hidden;
            padding: 0;
            text-indent: 15px;
            width: calc(100%);

            &:hover{
                outline: none;
            }
        }
        button{
            background-color: #dad1ca;
            border-radius: 5px;
            border: none;
            color: #1a1917;
            height: 50px;
            position: absolute;
            right: 0px;
            top: 0;
            width: calc(25%);
        }
    }
}
.form_label{
    align-self: flex-start;
}
.footer_form {
    --height: 50px;
    position: relative;
    width: calc(100%);
    input {
        background-color: #2b2a28 !important;
        border: none;
        border-radius: 5px;
        color: #fff;
        height: var(--height);
        text-indent: 15px;
        margin: 0px;
        outline: none;
        padding: 0px;
        width: 100%;
    }
    
    button {
        background-color: #dad1ca;
        border: none;
        border-radius: 5px;
        color: #000;
        cursor: pointer;
        height: var(--height);
        position: absolute;
        // top: 2px;
        transform: translateX(2px);
        right: 0px;
        width: 120px;
        z-index: 2; 

        &:hover{
            background-color: rgb(218, 209, 202, .5);
        }
    }
}
.footer_social_icons{
    display: flex;
    margin-top: 30px;

    a{
        margin-left: 20px;
        img{
            height: 40px;
        }
    }
}


.footer_copyright{
    // height: 150px;
    p{
        font-size: 16px;
        text-align: center;
    }
}

@media screen and (max-width: 1023px) {
    .footer{
        p{
            font-size: 14px;
        }
    }
    .footer_right_col{
        width: calc(60%);
    }
    .footer_form{
        --height: 40px;
        button{
            width: 80px;
        }
    }
    .footer_social_icons{
        img{
            width: 30px;
        }
    }
}

@media screen and (max-width: 767px) {
    .footer_inner{
        flex-direction: column;
    }
    .footer_left_col{
        margin-bottom: 30px;
        div{
            width: calc(100%);
        }
    }
    .footer_right_col{
        align-items: center;
        width: calc(100%);
    }
    .footer_form{
        button{
            width: 60px;
        }
    }
    .footer_copyright{
        p{
            font-size: 14px;
        }
    }
}
