.recommend_list_container {
    background-color: #1a1917;
    border-radius: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 600px;
    margin: 0px 10px;
    overflow-y: auto;
    padding: 10px;
    width: calc(25%);
}
.recommend_list_title{
    font-size: 18px;
}
.recommend_list_content{
    align-items: center;
    border-bottom: 1px solid #dad1ca;
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: 25px 0px;
    width: calc(100%);

    p{
        cursor: pointer;
        font-size: 15px;
        margin: 0px;
        width: calc(90%);
    }

    img{
        cursor: pointer;
    }

    &:hover{
        background-color: #2b2a28;
    }
    &:last-child{
        border: none;
    }
}

@media screen and (max-width: 1559px) {
    .recommend_list_container{
        width: calc(30%);
    }
}

@media screen and (max-width: 1023px) {
    .recommend_list_container {
        margin: 20px 0;
        width: calc(100%);
    }
}