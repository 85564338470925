.event_button{
    margin: 20px auto;
}
.event_table_btn{
    margin-top: 20px;
}

.image_btn {
    width: 250px;
    margin-left: 20px;

    &::-webkit-file-upload-button {
        background-color: #dad1ca;
        border: 1px solid #dad1ca;
        border-radius: 5px;
        cursor: pointer;
        color: #1a1917;
        font-family: 'montserrat';
        font-size: 15px;
        height: 40px;
        width: calc(100%);

        &:hover {
            background-color: transparent;
            color: #dad1ca;
        }
    }
}

@media screen and (max-width: 575px) {
    .image_btn {
        height: 30px;
        width: 120px;
        &::-webkit-file-upload-button {
            font-size: 12px;
            height: 30px;
            width: calc(100%);
        }
    }
}