.booking_form {
    align-items: center;
    background-color: #1a1917;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px auto 100px;
    padding: 30px;
    width: calc(50%);
}

.grecaptcha-badge {
    bottom: 100px !important;
    z-index: 10 !important;
}

.from_recaptcha {
    width: calc(100%);
}

@media screen and (max-width: 1279px) {
    .booking_form {
        width: calc(80%);
    }
}

@media screen and (max-width: 767px) {
    .booking_form {
        flex-direction: column;
        padding: 15px;
        width: calc(100% - 50px);
    }
}