.button{
    background-color: transparent;
    border: 1px solid #dad1ca;
    border-radius: 5px;
    cursor: pointer;
    color: #dad1ca;
    font-family: 'montserrat';
    font-size: 16px;
    height: 40px; 
    width: 250px;

    &:hover{
        background-color: #dad1ca;
        color: #2B2A28;
    }
}
.button_light{
    background-color: #dad1ca;
    color: #2B2A28;

    &:hover{
        background-color: #2B2A28;
        color: #dad1ca;
    }
}

.form_button{
    height: 50px;
    margin: 10px 0;
    width: calc(100%);
}

@media screen and (max-width: 767px) {
    .form_button {
        height: 40px;
        font-size: 13px;
    }
}

@media screen and (max-width: 575px) {
    .button {
        height: 30px;
        width: 120px;    
        font-size: 12px; 
    }
    .form_button {
        height: 40px;
        width: calc(100%);
    }
}