.navbar_top{
    background-color: #1a1917;
}
.navbar_social_icons{
    display: flex;
    a{
        margin-left: 15px;
        img {
            height: 25px;
        }
    }
}
.navbar_top_inner{
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: space-between;
    a{
        color: #fff;
        display: flex;
        font-size: 14px;
        text-decoration: none;
        span{
            margin-left: 10px;
        }
    }
}
.navbar{
    height: 150px;
    position: sticky;
    top: 0;
    transition: 0.3s;
    width: calc(100%);
    z-index: 99;
}

.navbar_container{
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: calc(100%);
}

.nav_menu_icon{
    display: flex;
    align-items: center;
    img{
        margin-left: 20px;
        cursor: pointer;
    }
}

.nav_menu{
    align-items: center;
    background-color: #2B2A28;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    left: 0px;
    position: absolute;
    top: 0;
    width: calc(100%);
    z-index: 10;
}
.nav_link{
    color: #dad1ca;
    font-family: 'montserrat';
    font-size: 20px;
    margin-top: 5vh;
    text-decoration: none;
}
.nav_menu_close{
    cursor: pointer;
    color: #dad1ca;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 22px;
    z-index: 999;
}

@media screen and (max-width: 767px) {
    .navbar_logo{
        width: 150px;
    }
    .navbar{
        height: 100px;
    }
}
@media screen and (max-width: 575px) {
    .navbar{
        height: 70px;
    }
    .navbar_social_icons {
        a{
            img {
                height: 20px;
            }
        }
    }
    .navbar_logo {
        width: 100px;
    }
    .nav_link{
        font-size: 16px;
    }

}