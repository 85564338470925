.why_us_section {
    align-items: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.why_us_content{
    margin-left: 250px;
    margin-right: 20px;
    width: calc(50%);
}
.why_us_btn_container{
    display: flex;
    button{
        margin-right: 30px;
    }
}
.why_us_swiper_container{
    width: calc(50%);
}

.why_us_swiper {
    .swiper-slide {
        background-color: #1a1917;
        height: 500px !important;
        width: 700px !important;
    }
}

@media screen and (max-width: 1559px) {
    .why_us_swiper{
        .swiper-slide {
            height: 30vw !important;
            width: calc(75%) !important;
        }
    }
    .why_us_content {
        margin-left: 170px;
    }
}
@media screen and (max-width: 1279px) {
    .why_us_swiper {
        .swiper-slide {
            height: 30vw !important;
            width: calc(80%) !important;
        }
    }
    .why_us_content {
        margin-left: 50px;
    }
}
@media screen and (max-width: 1023px) {
    .why_us_section {
        flex-direction: column;
    }
    .why_us_content {
        margin: 0px;
        width: calc(100% - 60px);
    }
    .why_us_btn_container {
        display: flex;
        margin-bottom: 30px;
        justify-content: space-evenly;
        button {
            margin: 0;
        }
    }
    .why_us_swiper_container {
        width: calc(100% - 60px);
    }
    .why_us_swiper {
        .swiper-slide {
            height: 50vw !important;
            width: calc(100%) !important;
        }
    
    }
}
@media screen and (max-width: 575px) {
    .why_us_swiper {
        .swiper-slide {
            height: 75vw !important;
            width: calc(100%) !important;
        }

    }
}