.event_input {
    background-color: transparent;
    border: none;
    color: #fff;
    height: 40px;
    width: 100px;

    &:focus {
        border-bottom: 1px solid #fff;
        outline: none;
    }

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        filter: invert(0.8);
        font-size: 14px;
    }

}
.event_dropdown {
    width: 100px;
    &:focus{
        background-color: #1a1917;
    }
}