body {
  background-color: #2B2A28;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* playfair-display-regular - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/playfair-display-v30-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/playfair-display-v30-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/playfair-display-v30-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/playfair-display-v30-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/playfair-display-v30-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/playfair-display-v30-latin-regular.svg#PlayfairDisplay') format('svg');
  /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/montserrat-v25-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/montserrat-v25-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/montserrat-v25-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./assets/fonts/montserrat-v25-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg');
  /* Legacy iOS */
}