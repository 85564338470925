.about_section_content{
    text-align: center;
    width: calc(70%);
    margin: 0 auto;
}

@media screen and (max-width: 767px) {
    .about_section_content{
        width: calc(100%);
        text-align: left;
    }
}