.search_container{
    width: calc(100%);
}
.search{
    display:  flex;
    align-items: center;
    justify-content: space-evenly;

    .text_field_label{
        margin: 0;
    }
}

.track_container {
    align-items: flex-start;
    display: flex;
    margin: 50px 0px;
    width: calc(100%);
}

.track_inner_container {
    margin-top: 30px;
    overflow-y: auto;
    width: calc(100%);
}
.track {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    &:hover {
        background-color: #1a1917;
    }
}
.track_content_container{
    cursor: pointer;
    display: flex;
    width: calc(80%);
}
.track_poster {
    background-color: #1a1917;
    border-radius: 100%;
    height: 70px;
    margin: 0 20px;
    width: 70px;
}
.track_content {
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
        font-size: 16px;
        margin: 0px;

        span {
            display: inline-block;
            margin-top: 5px;
            margin-right: 5px;
            font-size: 15px;

            &:not(:last-child)::after {
                content: ",";
            }
        }
    }
}
.track_icon_container{
    display: flex;
    justify-content: space-evenly;
    width: calc(20%);
}

.track_icon{
    cursor: pointer;
    margin-right: 15px;
}

@media screen and (max-width: 1023px) {
    .track_container {
        width: calc(100%);
    }

    .track_poster {
        height: 60px;
        width: 60px;
    }

    .track_content {
        p {
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 55vw;
            overflow: hidden;
            span {
                font-size: 13px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .track_container {
        flex-direction: column;
    }
    .track_inner_container {
        margin-top: 50px;
    }
    .track{
        align-items: flex-start;
        flex-direction: column;
    }
    .track_content{
        p{
            width: 60vw;;
        }
    }
    .track_icon_container{
        margin-top: 10px;
        width: calc(100%);
    }
}

@media screen and (max-width: 575px) {
    .track_poster {
        height: 50px;
        width: 50px;
        margin-left: 0;
    }

    .track_content {
        p {
            font-size: 14px;
        }
    }
}