.blog_page_container{
    background-color: #1a1917;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
}
.blog_page_image{
    height: 30vw;
    object-fit: cover;
    width: calc(100%);
}