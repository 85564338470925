.login_page_container{
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1023px) {
    .login_page_container {
        flex-direction: column-reverse;
    }
}